<template>
  <div class="contact font14">
    <div class="service borderBox font-white ">
      <div class="box borderBox flex flex-column col-center">
        <img src="../assets/imgs/kefu.png" alt="重庆微信开发">
        <div>
          <span>服务热线：</span>
          <span class="font20">{{$store.state.siteinfo.service_number}}</span>
        </div>
        <div class="mail">
          <span>邮件地址：</span>
          <span class="font20">{{$store.state.siteinfo.email}}</span>
        </div>
        <div class="font16">工作时间 {{$store.state.siteinfo.work_time}}</div>
        <div class="operation text-center cursor">
          <div @click="jumpOut(1)" class="communicate">立即在线沟通</div>
          <div @click="jumpOut(2)" class="more font-white">更多定制服务</div>
        </div>
      </div>
    </div>
    <div class="newsForm borderBox">
      <input v-model="name" class="borderBox" type="text" placeholder="姓名">
      <input v-model="phone" class="borderBox" type="number" placeholder="联系电话">
      <input class="borderBox" v-model="industry" type="text" placeholder="请输入行业（选填）">
      <div class="code flex col-center borderBox row-between">
        <input v-model="captcha" class="borderBox" type="text" placeholder="请输入验证码">
        <img onClick="this.src=this.src+'?'+Math.random()"
             :src="$store.state.baseURL + '/api'+'/Base/captcha'"
             alt="重庆微信商城开发">
      </div>
      <div class="btn flex col-center font-white text-center cursor">
        <div @click="subscribe()" class="subscribe">我要预约</div>
        <div @click="jumpOut(3)" class="register">立即注册体验</div>
      </div>
    </div>
    <div class="hot borderBox">
      <div class="font-ash86">今日热题</div>
      <div class="hotList">
        <div @click="toDetailed(item.id)" class="hotList_item cursor flex col-center" v-for="(item ,index) in hotList" :key="index">
          <div class="font-white bg-red" v-if="index === 0">{{ index + 1 }}</div>
          <div class="font-white bg-orange" v-if="index === 1">{{ index + 1 }}</div>
          <div class="font-white bg-yellow" v-if="index === 2">{{ index + 1 }}</div>
          <div class="font-white" v-if="index>2">{{ index + 1 }}</div>
          <div class="text-over-1">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <div class="QR_code borderBox flex col-center row-between">
      <div class="flex flex-column row-center text-center" v-for="(item,index) in codeList" :key="index">
        <img :src="item.src" alt="重庆小程序开发">
        <p>{{item.text}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {phoneReg} from "@/utils/reg";
import {api_IndustryHot} from "@/api/list";
import {Throttle} from "@/utils/public";

export default {
  name: "Contact",
  data(){
    return{
      hotList: [],
      codeList:[
        {id: 0, text: '微星球公众号' ,src:require('@/assets/imgs/ewm1.jpg')},
        {id: 1, text: '重庆互联网公众号',src:require('@/assets/imgs/ewm2.jpg')},
      ],
      codeSrc:'',
      phone:'',
      name:'',
      captcha:'',
      industry:''
    }
  },
  created() {
    this.api_IndustryHot()
  },
  methods:{
    jumpOut(type){
      switch (type){
        case 1:
          window.location.href = this.$store.state.service_address
          break
        case 2:
          window.location.href = this.$store.state.other_address
          break
        case 3:
          window.location.href = this.$store.state.register_address
          break
      }
    },
    // 详细
    toDetailed(id){
      this.$router.push({path: '/articleDetails?type=2&id='+id})
    },
    // 预约
    subscribe: Throttle(function () {
      const {name,phone,captcha,industry} = this
      if (!name) {
        this.$message({
          message: '请输入姓名',
          type: 'warning',
          offset:this.$store.state.offset
        });
      } else if (!phone) {
        this.$message({
          message: '请输入手机号',
          type: 'warning',
          offset:this.$store.state.offset
        });
      } else if (!phoneReg.test(phone)) {
        this.$message({
          message: '请输正确的手机号',
          type: 'warning',
          offset:this.$store.state.offset
        });
      } else if (!captcha) {
        this.$message({
          message: '请输入验证码',
          type: 'warning',
          offset:this.$store.state.offset
        });
      } else {
        this.submit(name, phone, captcha, industry)
      }
    },1000),
    // 今日热点
    async api_IndustryHot() {
      const data = await api_IndustryHot()
      this.hotList = data.data.list
    },
  }
}
</script>

<style lang="less" scoped>
.contact {
  > div {
    width: 100%;
    background: #FFFFFF;
    border-radius: 4px;
    margin-bottom: 20px;
  }

  .service {
    padding: 20px;
    .box {
      width: 310px;
      background: url("../assets/imgs/kefu_bg.png") no-repeat;
      border-radius: 4px;
      padding: 20px;
      img{
        width: 70px;
        height: 70px;
        border-radius: 50%;
        margin-bottom: 30px;
      }
      .mail{
        line-height: 40px;
      }
      .operation{
        width: 100%;
        margin-top: 19px;
        div{
          width: 100%;
          height: 40px;
          line-height: 40px;
          border-radius: 4px;
        }
        .communicate{
          background: #FFFFFF;
          color: #275AFF;
        }
        .more{
          border: 1px solid #FFFFFF;
          margin-top: 10px;
        }
      }
    }
  }

  .newsForm {
    padding: 20px;
    input{
      width: 310px;
      height: 40px;
      background:none;
      outline:none;
      border: 1px solid #DEDEDE;
      border-radius: 4px;
      padding: 0 10px;
      margin-bottom: 14px;
    }
    .code{
      width: 310px;
      border: 1px solid #DEDEDE;
      border-radius: 4px;
      padding: 5px 5px 5px 10px;
      input{
        width: 150px;
        height: 20px;
        border: none;
        margin-bottom: 0;
        padding: 0 5px 0 0;
      }
      img{
        flex: 0 0 96px;
        height: 30px;
      }
    }

    .btn{
      flex: 1;
      margin-top: 15px;
      div{
        width: 150px;
        height: 40px;
        line-height: 40px;
        background: #275AFF;
        border-radius: 4px;
      }
      .register{
        background: #FF952B;
        margin-left: 12px;
      }
    }
  }

  .hot {
    height: 311px;
    padding: 20px;
    .hotList {
      margin-top: 25px;

      .hotList_item {
        margin-bottom: 20px;

        div:first-child {
          width: 22px;
          height: 22px;
          line-height: 22px;
          background: #D5D5D5;
          border-radius: 4px;
          margin-right: 10px;
          font-family: Microsoft YaHei;
          font-style: italic;
          box-sizing: border-box;
          padding-left: 5px;
        }

        div:last-child {
          width: 274px;
        }
      }
    }
  }

  .QR_code {
    height: 214px;
    padding: 20px 28px;
    >div{
      width: 140px;
      img{
        width: 100%;
        height: 140px;
      }
      p{
        margin-top: 20px;
      }
    }
  }
}

</style>