<template>
  <div class="box center font14">
    <div class="articleDetails flex">
      <div class="left">
        <div class="article bg-white">
          <h1 class="font26 font-bold">{{ main.title }}</h1>
          <div class="author flex col-center">
            <img :src="$store.state.baseURL +main.avatar" alt="重庆微信营销系统">
            <div class="">
              <div class="font-bold">作者：{{ main.author }}</div>
              <div class="font-ash86 time">更新时间：{{ main.update_time }}</div>
            </div>
          </div>
          <div class="richText" v-html="main.content"></div>
        </div>
        <div class="font20 font-bold">更多行业分享</div>
        <div class="moreList borderBox bg-white">
          <div @click="toDetailed(item.id)" class="moreItem flex cursor" v-for="(item , index) in more_list" :key="index">
            <img :src="$store.state.baseURL + item.cover" alt="重庆网站建设">
            <div class="news font-ash86">
              <div class="font18 font-black text-over-1">{{ item.title }}</div>
              <div class="text-over-2 introduce">{{ item.description }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <Contact></Contact>
      </div>
    </div>
  </div>
</template>
<script>
import Contact from "@/components/Contact";
import {api_FunctionView, api_IndustryView, api_QualityView} from "@/api/list";
import merge from 'webpack-merge'
export default {
  name: "articleDetails",
  components: {
    Contact
  },
  metaInfo () {
    return {
      meta: [{
        name: 'keywords',
        content: this.keywords
      },{
        name: 'description',
        content: this.description
      }],
    }
  },
  data() {
    return {
      id: '',
      main: {},
      more_list: [],
      keywords:'',
      description:''
    }
  },
  created() {
    const {type, id} = this.$route.query
    this.id = id
    switch (type) {
        // 功能
      case '1':
        this.getData(api_FunctionView)
        break
        // 行业
      case '2':
        this.getData(api_IndustryView)
        break
        // 优质案例
      case '3':
        this.getData(api_QualityView)
        break
    }
  },

  methods: {
    async getData(api) {
      const data = await api({id: this.id})
      data.data.main[0].update_time = data.data.main[0].update_time.substring(0, 16)
      while(data.data.main[0].content.indexOf('<img src="/') != -1 ){
            data.data.main[0].content = data.data.main[0].content.replace('<img src="/', '<img style="max-width:100%;height:auto;" src="' + this.$store.state.baseURL +'/')
          }
      data.data.main[0].content = data.data.main[0].content.replace('<img', '<img style="max-width:100%;height:auto;" ')

      this.main = data.data.main[0]
      this.more_list = data.data.more_list
        this.keywords = data.data.main[0].seo_keywords
        this.description = data.data.main[0].seo_description
    },
    // 详细
    toDetailed(id) {
      this.$router.push({
        query:merge(this.$route.query,{'type':'2','id':id})
      })
      this.id= id
      this.getData(api_IndustryView)
      // this.$router.push({path: '/articleDetails?type=2&id=' + id})
    },
    // async api_FunctionView(){
    //   const data = await api_FunctionView({id:this.id})
    //   data.data.main[0].update_time=data.data.main[0].update_time.substring(0,16)
    //   this.main = data.data.main[0]
    //   this.more_list = data.data.more_list
    //   console.log(this.main)
    //   console.log(this.more_list)
    //  },
    // async api_IndustryView(){
    //   const data = await api_IndustryView({id:this.id})
    //   data.data.main[0].update_time=data.data.main[0].update_time.substring(0,16)
    //   this.main = data.data.main[0]
    //   this.more_list = data.data.more_list
    //  },
    // async api_QualityView(){
    //   const data = await api_QualityView({id:this.id})
    //   data.data.main[0].update_time=data.data.main[0].update_time.substring(0,16)
    //   this.main = data.data.main[0]
    //   this.more_list = data.data.more_list
    //  },
  }

}
</script>

<style lang="less" scoped>
.box {
  height: 100%;
  box-sizing: border-box;
  padding: 14px 0 40px;

  .articleDetails {
    width: 100%;
    height: 100%;

    .left {
      flex: 0 0 830px;
      min-height: 100px;
      margin-right: 20px;

      .article {
        padding: 40px;
        margin-bottom: 40px;

        .author {
          margin-top: 29px;

          img {
            width: 40px;
            height: 40px;
            background: #DEDEDE;
            border-radius: 50%;
            margin-right: 21px;
          }

          .time {
            margin-top: 8px;
          }
        }

        .richText {
          margin-top: 61px;
          img {
            max-width:100%;
            height:auto;
          }
        }
      }

      .moreList {
        margin-top: 11px;
        padding: 40px;
        border-radius: 4px;

        .moreItem {
          margin-bottom: 30px;

          img {
            width: 120px;
            height: 90px;
            border-radius: 4px;
            margin-right: 20px;
          }

          .news {
            > div {
              width: 576px;
            }

            > div:first-child {
              margin-top: 4px;
            }

            .introduce {
              height: 54px;
              line-height: 28px;
              margin-top: 12px;
            }
          }
        }
      }

    }

    .right {
      flex: 1;
    }
  }
}
</style>