<template>
  <div class="box center font14">
    <div class="case flex">
      <div class="left">
        <div class="carousel">
          <el-carousel height="370px">
            <el-carousel-item v-for="(item,index) in carouselList" :key="index">
              <a :href="item.url">
                <img :src="$store.state.baseURL+item.image" alt="重庆网站搭建">
              </a>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="project">
          <div class="list flex flex-wrap col-center">
            <div @click="toDetailed(item.id)" class="item cursor" v-for="(item , index) in caseList" :key="index">
              <img :src="$store.state.baseURL+item.cover" alt="重庆手机商城开发">
              <div class="mantle flex col-center row-center">
                <img :src="$store.state.baseURL+item.qrcode" alt="重庆公众号商城开发">
              </div>
              <div class="text text-center borderBox flex flex-column col-center font-ash86">
                <div class="font18 font-black text-over-1">{{ item.title }}</div>
                <div class="text-over-2 synopsis">{{ item.description }}</div>
                <div>行业：{{ item.industry }}</div>
              </div>
            </div>
          </div>
          <div @click="more()" class="btn text-center cursor " :class="btnStyle&&'btnBorder'">
            {{ btnStyle ? '没有更多了' : '加载更多功能' }}
          </div>

        </div>
      </div>
      <div class="right">
        <Contact></Contact>
      </div>
    </div>
  </div>
</template>

<script>
import Contact from "@/components/Contact"
import {api_QualityIndex} from "@/api/list";

export default {
  name: "case",
  components: {
    Contact
  },
  data() {
    return {
      carouselList: [],
      caseList: [],
      limit: 10,
      page: 1,
      count: 0,
      btnStyle: false
    }
  },
  created() {
    // 获取轮播图
    this.getBanner({id: 102}).then(e => {
      this.carouselList = e
    })
    this.api_QualityIndex()
  },
  methods: {
    // 文章详情
    toDetailed(id) {
      this.$router.push({path: '/articleDetails?type=3&id=' + id})
    },
    more() {
      if (this.page * this.limit < this.count) {
        this.page++
        this.api_QualityIndex()
      }
    },
    // 案例列表
    async api_QualityIndex() {
      const data = await api_QualityIndex({limit: this.limit, page: this.page})
      data.data.rows.map(item => {
        this.caseList.push(item)
      })
      this.count = data.data.total
      if (this.page * this.limit >= this.count && this.caseList.length > 0) {
        this.btnStyle = true
      } else {
        this.btnStyle = false
      }
      if ( this.caseList.length<1){
        this.btnStyle = true
      }
    },
  }
}
</script>

<style lang="less" scoped>
.box {
  height: 100%;
  box-sizing: border-box;
  padding: 14px 0 40px;

  .case {
    width: 100%;
    height: 100%;

    .left {
      flex: 0 0 830px;
      height: 100%;
      margin-right: 20px;
      background: #fff;

      .carousel {
        width: 100%;
        height: 370px;

        a {
          width: 100%;
          height: 100%;
        }

        img {
          width: 100%;
          height: 100%;
          border-radius: 4px 4px 0px 0px;
        }
      }

      .project {
        padding: 40px 20px 40px 40px;

        .list {
          .item:hover {
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.13);
          }

          .item {
            width: 234px;
            height: 325px;
            margin: 0 20px 40px 0;
            border: 1px solid #EBEBEB;
            border-radius: 4px;

            > img {
              width: 100%;
              height: 170px;
              border-radius: 4px 4px 0px 0px;
            }

            .text {
              padding: 20px;
              position: relative;
              z-index: 99;
              background: #fff;
              overflow: hidden;

              div {
                width: 196px;
              }

              .synopsis {
                height: 40px;
                line-height: 20px;
                margin: 15px 0 25px;
              }
            }

            .mantle {
              width: 234px;
              height: 170px;
              background: rgba(255, 255, 255, 0.5);
              border-radius: 4px 4px 0px 0px;
              position: absolute;
              z-index: 1;

              img {
                width: 100px;
                height: 100px;
                background: #ccc;
              }
            }
          }

          .item:hover {
            .mantle {
              transition: all 1s;
              transform: translateY(-172px);
            }
          }
        }

        .btn {
          width: 140px;
          height: 39px;
          line-height: 39px;
          border: 1px solid #275AFF;
          border-radius: 4px;
          margin: 0 auto;
          color: #275AFF;
        }
      }
    }

    .right {
      flex: 1;
    }

  }
}

</style>