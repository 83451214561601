<template>
  <div class="box center font14">
    <div class="searchList flex">
      <div class="left">
        <div class="result bg-white borderBox ">
          <div class="font-ash86">功能搜索结果</div>
          <div class="functionList flex flex-wrap col-center">
            <div @click="toDetailed(1,item.id)" class="functionItem flex col-center cursor" v-for="(item , index) in functionList" :key="index">
              <img :src="$store.state.baseURL+item.icon" alt="重庆网站建设">
              <div class="text">
                <div class="name font18 text-over-1">{{item.title}}</div>
                <div class="font-ash86 text-over-1">{{item.description}}</div>
              </div>
            </div>
            <div v-if="functionList.length<1" class="tips text-center font-ash86">暂无相关数据</div>
          </div>
          <div class="typeTitle font-ash86 ">文章搜索结果</div>
          <div class="articleList">
            <div @click="toDetailed(2,item.id)" class="articleItem flex cursor" v-for="(item , index) in industryList" :key="index">
              <img :src="$store.state.baseURL+item.cover"
                   alt="重庆网站搭建">
              <div class="news font-ash86">
                <div class="font18 font-black text-over-1">{{item.title}}</div>
                <div class="text-over-2 introduce">{{item.description}} </div>
                <div>更新时间：{{item.update_time}}</div>
              </div>
            </div>
            <div v-if="industryList.length<1" class="tips text-center font-ash86">暂无相关数据</div>
          </div>
        </div>
        <div class="font20 font-bold">更多行业分享</div>
        <div class="moreList borderBox bg-white">
          <div @click="toDetailed(item.id)" class="moreItem flex cursor" v-for="(item , index) in shareList" :key="index">
            <img :src="$store.state.baseURL+item.cover"
                 alt="重庆手机商城开发">
            <div class="news font-ash86">
              <div class="font18 font-black text-over-1">{{item.title}}</div>
              <div class="text-over-2 introduce">{{item.description}} </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <Contact></Contact>
      </div>
    </div>
  </div>
</template>

<script>
import Contact from "@/components/Contact"
import {api_FunctionIndex, api_IndustryIndex,api_IndustryRandom} from "@/api/list";

export default {
  name: "searchList",
  components: {
    Contact
  },
  data() {
    return {
      shareList:[],
      functionList:[],
      industryList:[],
      limit:50,
      industryListLimit:100,
      page:1,
    }
  },
  computed: {
    keywords() {
      return this.$store.state.keywords;
    }
  },
  watch: {
    keywords() {
      this.shareList = []
      this.functionList = []
      this.industryList = []
      this.api_FunctionIndex()
      this.api_IndustryIndex()
      this.api_IndustryRandom()
    }
  },
  created() {
    this.shareList = []
    this.functionList = []
    this.industryList = []
    this.api_FunctionIndex()
    this.api_IndustryIndex()
    this.api_IndustryRandom()
  },
  methods:{
    // 文章详情
    toDetailed(id) {
      this.$router.push({path: '/articleDetails?type=2&id='+id})
    },
    // 功能列表
    async api_FunctionIndex() {
      console.log(1321)
      const data = await api_FunctionIndex({limit: this.limit, page: this.page,keywords:this.keywords})
      data.data.list.map(item => {
        this.functionList.push(item)
      })
    },
    // 文章分类列表
    async api_IndustryIndex() {
      const data = await api_IndustryIndex({page:this.page,limit: this.limit, keywords: this.keywords})
      data.data.list.map(item=>{
        item.update_time=item.update_time.substring(0,16)
        this.industryList.push(item)
      })
      this.count = data.data.count
    },
    // 行业分享列表
    async api_IndustryRandom() {
      const data = await api_IndustryRandom({page:this.page,limit: this.limit, keywords: this.keywords})
      data.data.map(item=>{
        this.shareList.push(item)
      })
    },

  }
}
</script>

<style lang="less" scoped>
.tips{
  width: 100%;
  padding: 80px 0 80px;
}
.box {
  height: 100%;
  box-sizing: border-box;
  padding: 20px 0 40px;

  .searchList {
    width: 100%;
    height: 100%;

    .left {
      flex: 0 0 830px;
      min-height: 100px;
      margin-right: 20px;

      .result {
        border-radius: 4px;
        padding: 40px 40px 10px;
        margin-bottom: 41px;

        .functionList {
          margin-top: 20px;

          > div:nth-child(even) {
            margin-left: 16px;
          }

          .functionItem:hover {
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.13);
          }

          .functionItem {
            width: 365px;
            height: 87px;
            border: 1px solid #EBEBEB;
            border-radius: 4px;
            margin-bottom: 20px;

            img {
              width: 46px;
              height: 46px;
              border-radius: 10px;
              margin: 0 16px 0 18px;
            }

            .text {
              width: 250px;

              .name {
                margin-bottom: 9px;
              }
            }
          }
        }

        .typeTitle {
          margin-top: 20px;
        }

        .articleList {
          margin-top: 20px;

          .articleItem {
            margin-bottom: 30px;

            img {
              width: 150px;
              height: 110px;
              border-radius: 4px;
              margin-right: 20px;
            }

            .news {
              > div {
                width: 576px;
              }

              > div:first-child {
                margin-top: 4px;
              }

              .introduce {
                height: 36px;
                line-height: 18px;
                margin: 14px 0 18px;
              }
            }
          }
        }
      }

      .moreList {
        margin-top: 11px;
        padding: 40px;
        border-radius: 4px;
        .moreItem {
          margin-bottom: 30px;
          img {
            width: 120px;
            height: 90px;
            border-radius: 4px;
            margin-right: 20px;
          }

          .news {
            > div {
              width: 576px;
            }

            > div:first-child {
              margin-top: 4px;
            }

            .introduce {
              height: 54px;
              line-height: 28px;
              margin-top: 12px;
            }
          }
        }
      }
    }

    .right {
      flex: 1;
    }
  }
}
</style>