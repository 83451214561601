<template>
  <div class="box center font14">
    <div class="functionList flex">
      <div class="left">
        <div class="carousel">
          <el-carousel height="370px">
            <el-carousel-item v-for="(item,index) in carouselList" :key="index">
              <a :href="item.url">
                <img :src="$store.state.baseURL+item.image" alt="重庆微信开发">
              </a>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="project">
          <div class="list flex flex-wrap col-center">
            <div @click="toDetailed(item.id)" class="item flex col-center cursor" v-for="(item , index) in list"
                 :key="index">
              <img :src="$store.state.baseURL+item.icon"
                   alt="重庆微信商城开发">
              <div class="text">
                <div class="name font18 text-over-1">{{ item.title }}</div>
                <div class="font-ash86 text-over-1">{{ item.description }}</div>
              </div>
            </div>
          </div>
          <div @click="more()" class="btn text-center cursor " :class="listStyle&&'btnBorder'">
            {{ listStyle ? '没有更多了' : '加载更多功能' }}
          </div>
        </div>
      </div>
      <div class="right">
        <Contact></Contact>
      </div>
    </div>
  </div>

</template>
<script>
import Contact from "@/components/Contact"
import {api_FunctionIndex} from "@/api/list";

export default {
  name: "functionList",
  components: {
    Contact
  },
  data() {
    return {
      carouselList: [],
      list: [],
      limit: 20,
      page: 1,
      count: 0,
      listStyle: false
    }
  },
  created() {
    // 获取轮播图
    this.getBanner({id: 101}).then(e => {
      this.carouselList = e
    })
    this.api_FunctionIndex()
  },
  methods: {
    more() {
      if (this.page * this.limit < this.count) {
        this.page++
        this.api_FunctionIndex()
      }
    },
    // 详细
    toDetailed(id) {
      this.$router.push({path: '/articleDetails?type=1&id=' + id})
    },
    // 功能列表
    async api_FunctionIndex() {
      const data = await api_FunctionIndex({limit: this.limit, page: this.page})
      data.data.list.map(item => {
        this.list.push(item)
      })
      this.count = data.data.count
      if (this.page * this.limit >= this.count) {
        this.listStyle = true
      }
    },
  }
}
</script>

<style lang="less" scoped>


.box {
  height: 100%;
  box-sizing: border-box;
  padding: 14px 0 40px;

  .functionList {
    width: 100%;
    height: 100%;

    .left {
      flex: 0 0 830px;
      min-height: 100px;
      margin-right: 20px;

      .carousel {
        width: 100%;
        height: 370px;

        a {
          width: 100%;
          height: 100%;
        }

        img {
          width: 100%;
          height: 100%;
          border-radius: 4px 4px 0px 0px;
        }
      }

      .project {
        background: #fff;
        padding-bottom: 40px;

        .list {
          padding: 40px;

          > div:nth-child(even) {
            margin-left: 16px;
          }

          .item:hover {
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.13);
          }

          .item {
            width: 365px;
            height: 87px;
            border: 1px solid #EBEBEB;
            border-radius: 4px;
            margin-bottom: 20px;

            img {
              width: 46px;
              height: 46px;
              border-radius: 10px;
              margin: 0 16px 0 18px;
              background: #ccc;
            }

            .text {
              width: 250px;

              .name {
                margin-bottom: 9px;
              }
            }
          }
        }

        .btn {
          width: 140px;
          height: 39px;
          line-height: 39px;
          border: 1px solid #275AFF;
          border-radius: 4px;
          margin: 0 auto;
          color: #275AFF;
        }
      }
    }

    .right {
      flex: 1;
    }
  }
}
</style>