<template>
    <div class="box center font14">
        <div class="industry flex">
            <div class="left">
                <div class="carousel">
                    <el-carousel height="370px">
                        <el-carousel-item v-for="(item,index) in carouselList" :key="index">
                            <a :href="item.url">
                                <img :src="$store.state.baseURL+item.image" alt="重庆小程序开发">
                            </a>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="list bg-white borderBox">
                        <div class="division"></div>
                        <div @mouseleave="leave(1)" @mouseenter="enter(1)"
                             class="tabList tabList_top flex col-center row-between">
                            <div class="tabLeft flex">
                                <div @click="tab(item.id)" class="tabList_item font-ash86 text-center cursor"
                                     v-for="(item ,index) in List"
                                     :key="index"
                                >
                                    <div :class="current === item.id?'activeColor':''">{{ item.category_name }}</div>
                                    <div class="effect" :class="current === item.id?'effectActive':''">
                                        <div class="horn" :class="current === item.id?'hornActive':''"></div>
                                    </div>
                                </div>
                            </div>
                            <div @mouseenter="enter(3)" v-if="tabList.length>7"
                                 class="el-icon-arrow-down font20 font-ash86"></div>
                        </div>
                        <div @mouseleave="leave(2)" @mouseenter="enter(2)" v-if="tabState" ref="barparent" class="column"
                             :style="'transform: translateY('+height+'px);'">
                            <div class="tabList flex col-center flex-wrap borderBox">
                                <div @click="tab(item.id)" class="tabList_item font-ash86 text-center cursor"
                                     v-for="(item ,index) in boxList"
                                     :key="index">
                                    <div :class="current === item.id?'activeColor':''">{{ item.category_name }}</div>
                                    <div class="effect" :class="current === item.id?'effectActive':''">
                                        <div class="horn" :class="current === item.id?'hornActive':''"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <div class="articleList">
                        <div @click="toDetailed(item.id)" class="articleItem flex cursor" v-for="(item , index) in list"
                             :key="index">
                            <img :src="$store.state.baseURL+item.cover"
                                 alt="重庆微信营销系统">
                            <div class="news font-ash86">
                                <div class="font18 font-black text-over-1">{{item.title}}</div>
                                <div class="text-over-2 introduce">{{item.description}}</div>
                                <div>更新时间：{{item.update_time}}</div>
                            </div>
                        </div>
                        <div v-if="!listStyle" class="tips text-center font-ash86">暂无相关数据</div>
                    </div>
                    <div @click="more()" v-if="this.list.length>0" class="btn text-center cursor "
                         :class="btnStyle&&'btnBorder'">{{btnStyle?'没有更多了':'加载更多功能'}}
                    </div>
                </div>
            </div>
            <div class="right">
                <Contact></Contact>
            </div>
        </div>
    </div>
</template>

<script>
    import Contact from "@/components/Contact";
    import {api_IndustryCategory, api_IndustryIndex} from "@/api/list";

    export default {
        name: "industry",
        components: {
            Contact
        },
        computed: {
            List: function () {
                let arr1 = []
                this.tabList.map((item, index) => {
                    if (index < 7) {
                        arr1.push(item)
                    }
                })
                return arr1
            },
            boxList: function () {
                let arr2 = []
                this.tabList.map((item, index) => {
                    if (index > 6) {
                        arr2.push(item)
                    }
                })
                return arr2
            },
        },
        data() {
            return {
                carouselList: [],
                tabList: [],
                list: [],
                industryLimit: 50,
                industryListLimit: 10,
                current: 0,
                tabState: true,
                height: -200,
                columnHeight: 0,
                listStyle: false,
                btnStyle: false,
                page: 1,
                count: 0,
                box1: false,
                box2: false,
            }
        },
        async created() {
            // 获取轮播图
            await this.getBanner({id: 103}).then(e => {
                this.carouselList = e
            })
            await this.api_IndustryCategory()
            await this.api_IndustryIndex()
        },
        methods: {
            enter() {
                this.height = 0
            },
        leave() {

            this.height = -200

        },
        // 详细
        toDetailed(id) {
            this.$router.push({path: '/articleDetails?type=2&id=' + id})
        },
        tab(id) {
            if (this.current !== id) {
                this.list = []
                this.page = 1
                this.current = id
                this.api_IndustryIndex()
            }
        },
        // 加载更多
        more() {
            if (this.page * this.industryListLimit < this.count) {
                this.page++
                this.api_IndustryIndex()
            }
        },
        // 行业分类
        async api_IndustryCategory() {
            const data = await api_IndustryCategory({limit: this.industryLimit})
            this.tabList = data.data.list
            this.current = data.data.list[0].id
        },
        // 分类列表
        async api_IndustryIndex() {
            const data = await api_IndustryIndex({
                page: this.page,
                limit: this.industryListLimit,
                industry_id: this.current
            })
            data.data.list.map(item => {
                item.update_time = item.update_time.substring(0, 16)
                this.list.push(item)
            })
            this.count = data.data.count
            if (this.list.length < 1) {
                this.listStyle = false
            } else {
                this.listStyle = true
            }
            if (this.page * this.industryListLimit >= this.count && this.list.length > 0) {
                this.btnStyle = true
            } else {
                this.btnStyle = false
            }
        },
    }
    }
</script>

<style lang="less" scoped>
    .btnBorder {
        border: 1px solid #EBEBEB !important;
        color: #EBEBEB !important;
    }

    .activeColor {
        color: #275AFF;
    }

    .tabList_item:hover {
        color: #275AFF;
    }

    //激活样式
    .effectActive {
        border-bottom: 1px solid #275AFF !important;
        overflow: visible !important;
    }

    .hornActive {
        border-left: 1px solid #275AFF !important;
        border-top: 1px solid #275AFF !important;
    }

    .box {
        height: 100%;
        box-sizing: border-box;
        padding: 14px 0 40px;

        .industry {
            width: 100%;
            height: 100%;

            .left {
                flex: 0 0 830px;
                height: 100%;
                margin-right: 20px;

                .carousel {
                    width: 100%;
                    height: 370px;
                    position: relative;
                    z-index: 999;
                    background: #fff;

                    a {
                        width: 100%;
                        height: 100%;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 4px 4px 0px 0px;
                    }
                }

                .list {
                    padding: 0 40px 40px 40px;

                    .division {
                        width: 100%;
                        height: 40px;
                        background: #fff;
                        position: relative;
                        z-index: 999;
                    }
                }

                .tabList {
                    border-bottom: 1px solid #EBEBEB;

                    .tabList_item {
                        margin-right: 34px;
                        position: relative;

                        .effect {
                            width: 60px;
                            height: 5px;
                            border-bottom: 1px solid #EBEBEB;
                            margin-top: 11px;
                            position: relative;
                            bottom: -1px;
                            left: calc(50% - 30px);
                            overflow: hidden;

                            .horn {
                                width: 4px;
                                height: 4px;
                                border-left: 1px solid #fff;
                                border-top: 1px solid #fff;
                                transform: rotate(45deg);
                                -ms-transform: rotate(45deg); /* IE 9 */
                                -moz-transform: rotate(45deg); /* Firefox */
                                -webkit-transform: rotate(45deg); /* Safari 和 Chrome */
                                -o-transform: rotate(45deg);
                                position: absolute;
                                top: 3px;
                                left: 45%;
                                background: #fff;
                                z-index: 1111;
                            }
                        }
                    }

                }

                .tabList_top {
                    position: relative;
                    z-index: 999;
                    background: #fff;
                }

                .column {
                    width: 750px;
                    min-height: 49px;
                    background: #F7F7F7;
                    position: absolute;
                    z-index: 99;
                    transition: all 1s;
                    overflow: hidden;

                    .tabList {
                        border: none;
                        padding: 0 24px;

                        .tabList_item {
                            margin-top: 19px;

                            .effect {
                                bottom: 1px;
                                border: none;

                                .horn {
                                    background: #F7F7F7;
                                }
                            }
                        }
                    }
                }

                .articleList {
                    margin-top: 30px;

                    .tips {
                        padding: 80px 0 80px;
                    }

                    .articleItem {
                        margin-bottom: 30px;

                        img {
                            width: 150px;
                            height: 110px;
                            border-radius: 4px;
                            margin-right: 20px;
                        }

                        .news {
                            > div {
                                width: 576px;
                            }

                            > div:first-child {
                                margin-top: 4px;
                            }

                            .introduce {
                                height: 36px;
                                line-height: 18px;
                                margin: 14px 0 18px;
                            }
                        }
                    }
                }

                .btn {
                    width: 140px;
                    height: 39px;
                    line-height: 39px;
                    border: 1px solid #275AFF;
                    border-radius: 4px;
                    margin: 10px auto 0;
                    color: #275AFF;
                }


            }
        }
    }

</style>